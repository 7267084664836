import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import FontAwesomeIcon from "@fortawesome/vue-fontawesome";
// import VueGtag from "vue-gtag";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import JQuery from "jquery";
import Embed from 'v-video-embed'
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.config.productionTip = false
Vue.use(Embed);
Vue.use(VueSmoothScroll)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.component("font-awesome-icon", FontAwesomeIcon);
// Vue.use(VueGtag, {
//   config: { id: "" },
//   includes: [
//     { id: '' }
//   ]
// }, router);
new Vue({
  router,
  axios,
  JQuery,
  render: h => h(App),
}).$mount('#app')
