<template>
  <div class="GolfParkImages">
    <div class="homepage">
      <!-- <h2 class="title">Golf Park Images</h2> -->
      <b-row>
        <b-col v-for="(item, index) in acf.acf.images" :key="index" lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }">
        </b-col>
        <!-- <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/47492a40cfbc88325476766ae9799661.png' + ')' }">
        </b-col>
        <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/1bbcb15d89ce1f03e60ee52daff01387.png' + ')' }">
        </b-col>
        <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/04bfb816d5d3c2e57e74b79c573d040c.png' + ')' }">
        </b-col>
        <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/bb047fd1fc53b5d860854d474777659f.png' + ')' }">
        </b-col>
        <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/71a2d03dd436f4bb0bb624569fee97fb.png' + ')' }">
        </b-col>
        <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/013d220d5ba716f9f4ebad5b41be3f72.png' + ')' }">
        </b-col>
        <b-col lg="3" class="imageCol" v-bind:style="{ backgroundImage: 'url(' + 'https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/bdff93f6a867612c147777979c30a803.png' + ')' }">
        </b-col> -->
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Golf Park Images',
  components: {

  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'golfpark' + "/wp-json/wp/v2/pages/52"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
.title {
    color: #fff;
}
.GolfParkImages {
    /* background-color: #FDA000;
    padding: 50px; */
    overflow: hidden;
}
.Images {
    width: 100%;
    min-height: 250px;
}
.imageCol {
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 301px;
    height: 301px;
}
</style>
